

section {
  &.info {
    // opacity: 0;
    // position: absolute;
    // top:0px;
    // width: 200px;
    // left:0%;
    // z-index: -1;
    // height: fit-content;
    // background: rgb(8, 7, 27);
    // color:white!important;
    // box-shadow: 0 8px 16px 3px rgba( #000, .6 );
    // transition: 0.5s;
    // filter: blur(4px);

    
    h4 {
      padding: 10px;
    }

    h5 {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    
    p {
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      font-size: 12px;
      padding-bottom: 10px;
    }

    // .image {
    //   // background: $img_inside_hover;
    //   background-size: cover;
    //   background-position: center center;
    //   background-repeat: no-repeat;
      
    //   width: 100%;
    //   height: 120px;
    // }
  }
}





@keyframes loadingGame {
  to {
    background-position-x: -20%;
  }
}
.loading-Game{
  background-color:    rgba(217, 217, 217, 0.2)!important;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  )rgba(217, 217, 217, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loadingGame ease-in-out infinite;
}