/* @tailwind base; */
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Inter';
    src: url("./utils/fonts/Inter-VariableFont_slnt\,wght.ttf");
}

*, :after, :before {
    font-family: 'Inter';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body{
    background-color: #110f32;
}


